@use '../../utils' as *;

/*----------------------------------------*/
/*  4.2 Meanmenu css
/*----------------------------------------*/


.mean-remove {
	display: none !important;
}
.mean-container{
	margin-bottom: 40px;
	& a{
		&.meanmenu-reveal{
			width: 22px;
			height: 22px;
			padding: 13px 13px 11px 13px;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
			color: #fff;
			text-decoration: none;
			font-size: 16px;
			text-indent: -9999em;
			line-height: 22px;
			font-size: 1px;
			font-weight: 700;
			display: none !important;

		}
	}

	& .mean-push{
		float: left;
		width: 100%;
		padding: 0;
		margin: 0;
		clear: both;
	}

	& .mean-nav{
		background: none;
		margin-top: 0;
		float: left;
		width: 100%;

		& .wrapper{
			width: 100%;
			padding: 0;
			margin: 0;
		}

		& > ul{
			padding: 0;
			margin: 0;
			width: 100%;
			list-style-type: none;
			display: block !important;

			& > li{
				&:first-child{
					& > a{
						border-top: 0;
					}
				}
			}
		}

		& ul{
			padding: 0;
			margin: 0;
			width: 100%;
			list-style-type: none;
			& li{
				position: relative;
				float: left;
				width: 100%;
			
				// if dropdown opened
				&.dropdown-opened{
					& > a,
					& > span{

						&.mean-expand.mean-clicked{
							color: var(--tp-common-white);
							& i{
								color: var(--tp-common-black);
							}
						}
					}
				}

				&.mean-last{
					border-bottom: none;
					margin-bottom: 0;
				}

				& > a{
					&.mean-expand{
						& i{
							display: inline-block;
						}
					}
					& > i{
						display: none;
					}
				}

				& a{
					display: block;
					float: left;
					width: 90%;
					padding: 10px 5%;
					margin: 0;
					text-align: left;
					color: #fff;
					text-decoration: none;
					text-transform: uppercase;
					width: 100%;
					padding: 10px 0;
					color: #4a4a4a;
					border-top: 1px solid #ebebeb;
					font-size: 14px;
					line-height: 1.5;
					font-weight: 700;

					
					@include rtl{
						float: right;
						text-align: right;
					}
					&:hover{
						color: var(--tp-theme-primary);
						& i{

						}
					}

					&.mean-expand{
						margin-top: 1px;
						width: 26px;
						height: 32px;
						text-align: center;
						position: absolute;
						right: 0;
						top: 0;
						z-index: 2;
						font-weight: 700;
						background: transparent;
						border: none !important;
						font-size: 14px;
						margin-top: 5px;
						padding: 0 !important;
						line-height: 14px;
						height: 30px;
						width: 30px;
						line-height: 30px;
						color: #4a4a4a;
						line-height: 30px;
						top: 0;
						font-weight: 400;

						@include rtl{
							right: auto;
							left: 0;
							text-align: center;
						}
						& i{
							transition: all .3s ease-in-out;
						}

						&.mean-clicked{
							& i{
								transform: rotate(180deg);
								-webkit-transform: rotate(180deg);
								-moz-transform: rotate(180deg);
								-ms-transform: rotate(180deg);
								-o-transform: rotate(180deg);
								color: var(--tp-common-black);
							}
							&:hover{
								border-color: var(--tp-theme-primary);
							}
						}
					}
				}

				& li{
					& a{
						width: 80%;
						padding: 10px 5%;
						text-shadow: none !important;
						visibility: visible;
					}

					& li{
						& a{
							width: 70%;
							padding: 10px 15%;
						}

						& li{
							& a{
								width: 60%;
								padding: 10px 20%;
							}

							& li{
								& a{
									width: 50%;
									padding: 10px 25%;
								}
							}
						}
					}
				}
			}
		}
	}

	& .mean-bar{
		padding: 0;
		min-height: auto;
		background: none;
		float: left;
		width: 100%;
		position: relative;
		padding: 4px 0;
		min-height: 42px;
		z-index: 999999;
		margin-bottom: 50px;
	}

	& .mean-bar,
	& .mean-bar *{
		/* Fix for box sizing on Foundation Framework etc. */
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}
}
