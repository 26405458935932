@use '../../utils' as *;

/*----------------------------------------*/
/*  7.1 Slider css
/*----------------------------------------*/


.swiper-slide{
    &.swiper-slide-active{
        & .tp-hero{
            &-title{
                animation-fill-mode: both;
                &-wrapper{
                    & .tp-section-title__pre{
                        animation-fill-mode: both;
                        & svg {
                            bottom: -14px;
                        }
                    }
                }
            }
            &-btn{
                & .tp-btn{
                    animation-fill-mode: both;
                }
            }
            &-bg{
                visibility: visible;
                -webkit-transform: scale(1.2);
                transform: scale(1.2);
            }

            &-3-title{
                animation-fill-mode: both;
                &-wrapper{
                    & .tp-section-title__pre{
                        animation-fill-mode: both;
                    }
                }
            }
            &-3-btn{
                & .tp-btn-2{
                    animation-fill-mode: both;
                }
            }
        }


        &-3{
            &-wrapper{
                visibility: visible;
                -webkit-transform: scale(1.3);
                transform: scale(1.3);
            }
        }
    }
}

.splide__track--nav > .splide__list > .splide__slide.is-active{
    border: none !important;
}
.splide__track--nav > .splide__list > .splide__slide{
    border: none !important;
}