@use '../../utils' as *;


/*----------------------------------------*/
/*  8.3 testimonial css
/*----------------------------------------*/


.tp-business{
    &-area{
        overflow: hidden;
    }
    &-shape{
        & .shape-1{
            position: absolute;
            top: 223px;
            left: 325px;
            z-index: 1;
            animation: moving 9s linear infinite;
            @media #{$xl} {
                top: 20%;
                left: 10%;
            }
            @media #{$lg} {
                top: 150px;
                left: 120px;
            }
        }
        & .shape-2{
            position: absolute;
            top: 132px;
            right: 175px;
            animation: tpupdown 1s infinite alternate;
            @media #{$lg} {
                top: 90px;
                right: 70px;
            }
            @media #{$md} {
                top: 100px;
                right: 60px;
            }
        }
        & .shape-3{
            position: absolute;
            top: 50px;
            left: 34px;
            animation: jumpTwo 5s infinite linear;
        }
        & .shape-4{
            position: absolute;
            top: 40%;
            left: 0;
            @media #{$xs} {
                top: 35%;
            }
        }
    }
    &-thumb{
        &-wrapper{
            transform: translateX(-12%);
            @media #{$xl,$lg,$md,$sm,$xs} {
                transform: translateX(0);
            }
            @media #{$md,$sm,$xs} {
                margin-bottom: 120px;
            }
        }
        & img{
            position: relative;
            transform: translateY(-8%);
            z-index: 1;
            border-radius: 50%;
            @media #{$md,$sm,$xs} {
                transform: translateY(0%);
            }
            @media #{$xs} {
                max-width: 100%;
            }
        }
        &-shape{
            @media #{$xs} {
                text-align: center;
            }
            & .shape-1{
                position: absolute;
                bottom: -12px;
                left: 30px;
                z-index: 1;
                @media #{$lg} {
                    left: -10px;
                }
                @media #{$xs} {
                    position: static;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
            & .shape-2{
                position: absolute;
                bottom: -72px;
                left: 200px;
                z-index: 1;
                @media #{$lg} {
                    left: 145px;
                }
                @media #{$xs} {
                   position: static;
                   margin-left: 30px;
                }
            }
        }
    }
    &-title{
        &-wrapper{
            margin-bottom: 40px;
            & p{
                position: relative;
                font-weight: 400;
                font-size: 16px;
                line-height: 29px;
                color: #5A627D;
                margin-bottom: 0;
                margin-left: 10px;
                & br{
                    @media #{$lg,$md,$sm,$xs} {
                        display: none;
                    }
                }
                &::after {
                    position: absolute;
                    content: "";
                    background: var(--tp-theme-secondary);
                    width: 2px;
                    height: 41px;
                    left: -10px;
                    top: 10px;
                    @media #{$lg,$sm,$xs} {
                        height: 70px;
                    }
                  }
            }
        }
    }
    &-box{
        border: 1px solid #E0E8E7;
        border-radius: 6px;
        padding: 15px 15px 26px 22px;
        transition: all .3s;
        &:hover {
            border-color: var(--tp-theme-secondary);
          }
        &-title{
            margin-bottom: 20px;
            & span{
                background: rgba(255, 255, 255, 0.52);
                box-shadow: 0px 4px 14px rgba(65, 118, 167, 0.11);
                border-radius: 83px;
                color: var(--tp-theme-secondary);
                height: 54px;
                width: 54px;
                text-align: center;
                line-height: 52px;
                margin-right: 12px;
                flex: 0 0 auto;
            }
        }
        & p{
            font-weight: 400;
            font-size: 16px;
            line-height: 29px;
            color: #5A627D;
            margin-bottom: 0;
            & br{
                @media #{$xl,$lg,$md,$xs} {
                    display: none;
                }
            }
        }
    }
    &-title{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: var(--tp-theme-primary);
        margin-bottom: 0;
    }
    &-btn-area {
        & img {
            margin-left: 20px;
            transform: translateY(5px);
          }
        & i{
            font-family: var(--tp-ff-dm-sans);
            position: relative;
            font-weight: 500;
            font-size: 18px;
            font-style: normal;
            text-align: center;
            text-transform: capitalize;
            color: var(--tp-theme-primary);
            margin-left: 5px;
            & span{
                position: absolute;
                bottom: -12px;
                right: -2px;
            }
            @media #{$lg,$md,$sm} {
                margin-left: 30px;
            }
            @media #{$xs} {
                display: none;
            }
        }
    }
}


.circle-animation{
    @media #{$md,$sm,$xs} {
        display: none;
    }
    &.business-2 {
        top: 50%;
        left: 50%;
        border: 1px solid #EDF1F8;
        width: 480px;
        height: 480px;
        position: absolute;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        @media #{$md}{
            top: 28%;
            width: 700px;
            height: 700px;
        }
        @media #{$xs}{
            top: 32%;
            width: 330px;
            height: 330px;
        }
    }
    &.business-3 {
        top: 50%;
        left: 50%;
        border: 1px solid #EDF1F8;
        width: 368px;
        height: 368px;
        position: absolute;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        @media #{$md}{
            top: 28%;
            width: 700px;
            height: 700px;
        }
        @media #{$xs}{
            top: 32%;
            width: 330px;
            height: 330px;
        }
    }
    &.business-4 {
        top: 50%;
        left: 50%;
        border: 1px solid #EDF1F8;
        width: 600px;
        height: 600px;
        position: absolute;
        transform: translate(-50%,-50%);
        border-radius: 50%;
        @media #{$xl,$lg} {
            display: none;
        }
        @media #{$md}{
            top: 28%;
            width: 700px;
            height: 700px;
        }
        @media #{$xs}{
            top: 32%;
            width: 330px;
            height: 330px;
        }
    }
    & span{
        &.tp-circle-1 {
            display: block;
            position: absolute;
            content: "";
            top: calc( 50% - 1px);
            left: 50%;
            width: 50%;
            height: 1px;
            background: transparent;
            transform-origin: left;
            @include animation-circle(circle-animation, 13s);
            &::before {
                position: absolute;
                content: "";
                width: 12px;
                height: 12px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                top: -25px;
                right: -6px;
                background-color: var(--tp-theme-secondary);
            }
        }
        &.tp-circle-2 {
            display: block;
            position: absolute;
            content: "";
            top: calc(50% - 1px);
            left: 50%;
            width: 50%;
            height: 1px;
            background: transparent;
            transform-origin: left;
            transform: scale(-1, 1);
            -webkit-animation: circle-animation2 7s linear infinite;
            -moz-animation: circle-animation2 7s linear infinite;
            -o-animation: circle-animation2 7s linear infinite;
            animation: circle-animation2 7s linear infinite;
            &::after {
                position: absolute;
                content: "";
                width: 14px;
                height: 14px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                bottom: -25px;
                right: -7px;
                background-color: #FFA654;
            }
        }
        &.tp-circle-3 {
            display: block;
            position: absolute;
            content: "";
            top: calc( 50% - 1px);
            left: 50%;
            width: 50%;
            height: 1px;
            background: transparent;
            transform-origin: left;
            -webkit-animation: circle-animation3 10s linear infinite;
            -moz-animation: circle-animation3 10s linear infinite;
            -o-animation: circle-animation3 10s linear infinite;
            animation: circle-animation3 10s linear infinite;
            &::before {
                position: absolute;
                content: "";
                width: 12px;
                height: 12px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                top: -25px;
                right: -6px;
                background-color: var(--tp-theme-secondary);
            }
        }

        
        &.tp-circle-5 {
            display: block;
            position: absolute;
            content: "";
            top: calc(50% - 1px);
            left: 50%;
            width: 50%;
            height: 1px;
            background: transparent;
            transform-origin: left;
            transform: rotate(-50deg);
            @include animation-circle(circle-animation8, 13s);
            &::after {
                position: absolute;
                content: "";
                width: 14px;
                height: 14px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                bottom: -25px;
                right: -7px;
                background-color: #EF5A32;
            }
        }
        &.tp-circle-6 {
            display: block;
            position: absolute;
            content: "";
            top: calc( 50% - 1px);
            left: 50%;
            width: 50%;
            height: 1px;
            background: transparent;
            transform-origin: left;
            @include animation-circle(circle-6, 13s);
            &::before {
                position: absolute;
                content: "";
                width: 12px;
                height: 12px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                top: -25px;
                right: -6px;
                background-color: #EF5A32;
            }
        }
        &.tp-circle-7 {
            display: block;
            position: absolute;
            content: "";
            top: calc( 50% - 1px);
            left: 50%;
            width: 50%;
            height: 1px;
            background: transparent;
            transform-origin: left;  
            @include animation-circle(circle-animation99, 8s);
            &::before {
                position: absolute;
                content: "";
                width: 12px;
                height: 12px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                top: -25px;
                right: -6px;
                background-color: #FFA654;
            }
        }
        &.tp-circle-8 {
            display: block;
            position: absolute;
            content: "";
            top: calc( 50% - 1px);
            left: 50%;
            width: 50%;
            height: 1px;
            background: transparent;
            transform-origin: left;
            @include animation-circle(circle-animation88, 13s);
            &::before {
                position: absolute;
                content: "";
                width: 12px;
                height: 12px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                top: -25px;
                right: -6px;
                background-color: #25A4FF;
            }
        }
    }
}
