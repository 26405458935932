@use "../../utils" as *;

/*----------------------------------------*/
/*  8.8 contact css
/*----------------------------------------*/

.tp-contact {
    &-title {
        font-weight: 700;
        font-size: 45px;
        line-height: 65px;
        display: inline-block;
        text-transform: uppercase;
        color: var(--tp-heading-secondary);
        margin-bottom: 35px;
        @media #{$xs} {
            font-size: 34px;
        }
        & span {
            color: var(--tp-theme-secondary);
        }
        &-wrapper {
            & p {
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
                color: #7f7f8c;
                margin-bottom: 40px;
                & br {
                    @media #{$xs} {
                        display: none;
                    }
                }
            }
        }
    }
    &-area {
        @media #{$xs} {
            padding-top: 0;
        }
    }
    &-wrapper {
        @media #{$xs} {
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 30px;
        }
    }
    &-content {
        @media #{$md,$sm,$xs} {
            margin-bottom: 40px;
        }
        &-mail {
            margin-bottom: 10px;
            &-icon {
                margin-right: 55px;
                @media #{$xs} {
                    margin-right: 25px;
                }
                & span {
                    color: var(--tp-theme-secondary);
                }
            }
        }
        &-address {
            margin-bottom: 50px;
            &-icon {
                margin-left: 6px;
                margin-right: 62px;
                @media #{$xs} {
                    margin-right: 35px;
                }
                & span {
                    color: var(--tp-theme-secondary);
                    & svg {
                        transform: translateX(12px);
                    }
                }
            }
        }
    }
    &-location {
        &-wrapper {
            @media #{$xs} {
                flex-wrap: wrap;
            }
        }
        &-title {
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            text-transform: capitalize;
            color: var(--tp-heading-secondary);
            margin-bottom: 25px;
            & i {
                color: var(--tp-heading-secondary);
                font-size: 16px;
            }
        }
        & p {
            font-weight: 400;
            font-size: 18px;
            line-height: 36px;
            color: #6f7183;
        }
    }
    &-item-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 2.8;
        color: var(--tp-heading-secondary);
        border-bottom: 1px solid #d9d9d9;
        @media #{$xs} {
            line-height: 1.8;
            font-size: 19px;
        }
        &:hover {
            color: var(--tp-theme-secondary);
        }
    }
    &-form {
        background: var(--tp-common-white);
        box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.04);
        border-radius: 9px;
        padding: 60px 50px 40px 60px;
        position: relative;
        @media #{$lg} {
            padding: 60px 30px 40px 30px;
        }
        @media #{$xs} {
            padding: 60px 25px 40px 25px;
        }
        &-title {
            font-weight: 500;
            font-size: 38px;
            line-height: 45px;
            text-transform: capitalize;
            color: #08151f;
            @media #{$xs} {
                font-size: 30px;
            }
        }
        & p {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            color: #555555;
            margin-bottom: 30px;
            & br {
                @media #{$lg,$md,$sm,$xs} {
                    display: none;
                }
            }
        }
    }
    &-input {
        margin-bottom: 26px;
        position: relative;
        z-index: 5;
        & input {
            width: 100%;
            height: 60px;
            border: 1px solid #d4dce0;
            background-color: #f8f8f9;
            outline: 0;
            color: var(--tp-common-black);
            padding: 0 25px;
            border-radius: 2px;
            &::placeholder {
                font-weight: 400;
                font-size: 18px;
                line-height: 23px;
                color: rgba(20, 33, 43, 0.8);
            }
            &:focus {
                border: 1px solid var(--tp-theme-secondary);
                &::placeholder {
                    font-size: 0;
                }
            }
        }
        & .dropdown {
            &-menu {
                border-radius: 2px;
                width: 100%;
            }
            &-toggle {
                padding: 0 14px 0 25px;

                width: 100%;
                height: 60px;
                border: 1px solid #d4dce0;
                background-color: #f8f8f9;
                outline: 0;
                color: var(--tp-common-black);
                border-radius: 2px;

                font-weight: 400;
                font-size: 18px;
                line-height: 23px;
                color: rgba(20, 33, 43, 0.8);

                text-align: left;

                display: flex;
                justify-content: space-between;
                align-items: center;

                &::after {
                    display: none;
                }
            }
            &-item {
                padding-left: 25px;
            }
        }
        & textarea {
            height: 271px;
            background-color: #f8f8f9;
            resize: none;
            line-height: 3.5;
            border-radius: 2px;
            border: 1px solid #d4dce0;
            &::placeholder {
                font-weight: 400;
                font-size: 18px;
                color: rgba(20, 33, 43, 0.8);
            }
            &:focus {
                background: var(--tp-common-white);
                border: 1px solid var(--tp-theme-secondary);
                &::placeholder {
                    font-size: 0;
                }
            }
        }
    }
    &-cta-area {
        margin-top: -165px;
    }
    &-map {
        height: 580px;
        width: 100%;
        & iframe {
            height: 100%;
            width: 100%;
            filter: saturate(0);
        }
    }
    &-btn {
        & .spinner-grow {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -1rem;
            margin-left: -1rem;
        }
    }
}
