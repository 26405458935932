@use '../utils' as *;

/*----------------------------------------*/
/*  2.12 Accordion
/*----------------------------------------*/

.tp-accordion{
    
    & .accordion-item{
        border: 1px solid transparent;
        &:first-of-type{
            & .accordion-button{
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
        &:last-of-type{
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            & .accordion-button{
                &.collapsed{
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    & .accordion-button {
        border: 0;
        // padding-right: 15px;
        background-color: transparent;
        box-shadow: none;

        &::after{
            display: none;
        }
    }

    & .accordion-body{
        border: 0;
        padding: 0 var(--bs-accordion-btn-padding-x);
    }
}
