.platform {
    &__tp-hero-area {
        height: 564px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @media (max-width: 600px) {
            height: 300px;
        }
    }

    &-area {
        padding-top: 100px;

        @media (max-width: 990px) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    & .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        width: 100%;
        height: 100%;
    }

    &__title {
        position: relative;
        font-weight: 600;
        font-size: 46px;
        margin-bottom: 40px;
        font-family: var(--tp-ff-raleway);
        color: var(--tp-theme-primary);

        @media (max-width: 600px) {
            font-size: 32px;
        }

        &-primary {
            color: var(--tp-theme-secondary);
        }

        & .title-right-shape {
            position: absolute;
            right: 0px;
            bottom: -25px;
        }
    }

    &__description {
        font-weight: 400;
        font-size: 26px;
        color: var(--tp-common-black);

        @media (max-width: 600px) {
            font-size: 20px;
        }
    }

    &__list-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 18px;
    }

    &__list-description {
        color: var(--tp-common-black);
        font-size: 20px;

        @media (max-width: 768px) {
            font-size: 18px;
            text-align: center;
        }
    }
}
