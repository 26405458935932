@use '../utils' as *;
/*-----------------------------------------------------------------------------------

    Template Name: Technix - Technology & IT Solutions HTML Template
    Author: Theme_Pure
    Support: help.themepure.net
    Description: Technix - Technology & IT Solutions HTML Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

	-----------------
    01. THEME CSS
	-----------------
		1.1 Theme Default
		1.2 Common Classes
		1.3 Default Spacing
		
	-----------------
    02. COMPONENTS css
	-----------------
		2.1 Back to top
		2.2 Theme Settings
		2.3 Buttons
		2.4 Animations
		2.5 Preloader
		2.6 Background 
		2.7 Carousel
		2.8 Nice Select
		2.9 Pagination
		2.10 Offcanvas
		2.11 Breadcrumb
		2.12 Accordion
		2.13 Tab
		2.14 Modal
		2.15 Section Title
	-----------------
    03. HEADER CSS
	-----------------
		3.1 Header Style 1
		3.2 Header Style 2
		3.3 Header Style 3

    ---------------------------------
	04. MENU CSS
	---------------------------------
		4.1 Main menu css
		4.2 Meanmenu css

	---------------------------------
	05. BLOG CSS
	---------------------------------
		5.1 Postbox css
		5.2 Recent Post css
		5.3 Sidebar css

	---------------------------------
	06. FOOTER CSS
	---------------------------------
		6.1 Footer Style 1
		6.2 Footer Style 2
		6.3 Footer Style 3
	
	---------------------------------
	07. PAGES CSS
	---------------------------------
		7.1 Slider css
		7.2 About css
		7.3 hero css
		7.4 feature css
		7.5 service css
		7.6 fun fact css
		7.7 brand css
		7.8 support css
		7.9 testimonial css
		8.0 blog css
		8.1 offer css
		8.2 portfolio css
		8.3 business css
		8.4 industry css
		8.5 cta css
		8.6 category css
		8.7 team css
		8.8 contact css
		8.9 faq css



**********************************************/


/*----------------------------------------*/
/*  1.1 Theme Default
/*----------------------------------------*/


*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.container-large, 
.container-large-lg, 
.container-large-md, 
.container-large-sm, 
.container-large-xl, 
.container-large-xxl {
	@media (min-width: 1400px){
		max-width: 1350px;
	}
}

.container-1400,
.container-1400-lg,
.container-1400-md,
.container-1400-sm,
.container-1400-xl,
.container-1400-xxl {
	@media (min-width: 1400px) {
		max-width: 1400px;
	}
}

/*---------------------------------
	typography css start 
---------------------------------*/
.body {
	font-family: var(--tp-ff-dm-sans);
	font-size: var(--tp-fz-body);
	font-weight: normal;
	color: var(--tp-text-body);
	line-height: 26px;
}

a{
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--tp-ff-dm-sans);
	color: var(--tp-heading-primary);
	margin-top: 0px;
	font-weight: 700;
	line-height: 1.2;
	@include tp-transition(color);
}

h1 {
	font-size: 40px;
}
  
h2 {
font-size: 38px;
}

h3 {
font-size: 28px;
}

h4 {
font-size: 24px;
}

h5 {
font-size: 22px;
}

h6 {
font-size: 18px;
}

ul {
	margin: 0px;
	padding: 0px;
}

p {
	font-family: var(--tp-ff-dm-sans);
	font-size: var(--tp-fz-p);
	font-weight: 400;
	color: var(--tp-text-body);
	margin-bottom: 15px;
	line-height: 29px;
}


a,
button,
p,
input,
select,
textarea,
li,
.transition-3{
	@extend %tp-transition-common;
}

a:not([href]):not([class]), 
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}

a:focus,
a:hover{
	color: inherit;
	text-decoration: none;
}



a,
button {
	color: inherit;
	outline: none;
	border: none;
	background: transparent;
}

button:hover{
	cursor: pointer;
}

button:focus{
    outline: 0; 
}

.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="url"],
textarea{
	outline: none;
	background-color: #EFF0F2;
	height: 54px;
	width: 100%;
	line-height: 54px;
	font-size: 16px;
	border: 2px solid #EFF0F2;
	color: var(--tp-common-black);
	padding-left: 29px;
	padding-right: 29px;

	@include rtl{
		text-align: right;
	}

	@include tp-placeholder{
		color: #84848B;
	}

}

input[type="color"] {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: none;
	border: 0;
	cursor: pointer;
	height: 100%;
	width: 100%;
	padding: 0;
	border-radius: 50%;
}

*::-moz-selection {
	background: var(--tp-common-black);
	color: var(--tp-common-white);
	text-shadow: none;
}
::-moz-selection {
	background: var(--tp-common-black);
	color: var(--tp-common-white);
	text-shadow: none;
}
::selection {
	background: var(--tp-common-black);
	color: var(--tp-common-white);
	text-shadow: none;
}


*::-moz-placeholder {
	color: var(--tp-common-black);
	font-size: var(--tp-fz-body);
	opacity: 1;
}
*::placeholder {
	color: var(--tp-common-black);
	font-size: var(--tp-fz-body);
	opacity: 1;
}


.test-class{
	padding: 5px 20px;
	background-color: green;
	color: #000;
	position: relative;
	overflow: hidden;
	&::after,
	&::before{
		position: absolute;
		content: '';
		left: -23px;
		bottom: -7px;
		width: 79%;
		height: 10px;
		background-color: #fff;
		border-radius: 50%;
	}

	&::before{
		left: auto;
		right: -23px;
		top: -7px;
		bottom: auto;
	}

	& span{
		position: relative;

		&::after,
		&::before{
			position: absolute;
			content: '';
			left: -23px;
			bottom: -7px;
			width: 79%;
			height: 10px;
			background-color: red;
			border-radius: 50%;

		}
	}
}



///mousemove///
.mousemove {
    --x: 0;
    --y: 0;
    --d: 50;
     
     &:hover .mousemove__image-wrapper {
       transform: 
         scale(1.025)
         translate(
           calc(var(--x) / var(--d) * 1px),
           calc(var(--y) / var(--d) * 1px)
         );
     }
   }
   
   .mousemove__image {
     --d: 20;
   
     transform: 
       translate(
         calc(var(--x) / var(--d) * 1px),
         calc(var(--y) / var(--d) * 1px)
       );
   }
   
   .mousemove__content {
     --d: 60;
   
     transform: 
       translate(
         calc(var(--x) / var(--d) * -1px),
         calc(var(--y) / var(--d) * -1px)
       );
   }
   
   // ========================
   // mousemove image wrapper
   // ========================
   
   .mousemove__image-wrapper {
    height: 67%;
    // transition: transform calc(var(--base-duration) / 1) var(--base-ease);
	@include transition(.3s);
    width: 50%;
   }
   
   // ========================
   // mousemove image
   // ========================
   
   .mousemove__image {
     --d: 20;
     object-fit: cover;
     pointer-events: none;
     position: absolute;
	 @include transition(.3s);
     user-select: none;
   }


