@use '../../utils' as *;

/*----------------------------------------*/
/*  7.2 About css
/*----------------------------------------*/

.box-plr{
    padding-left: 300px;
    padding-right: 99px;
    @media #{$xxxxl} {
        padding-left: 240px;
    }
    @media #{$xxxl} {
        padding-left: 200px;
    }
    @media #{$xxl} {
        padding-left: 100px;
    }
    @media #{$xl,$lg,$md,$sm,$xs} {
        padding-left: 0;
        padding-right: 0;
    }
}

.tp-about{
    &-area{
        overflow: hidden;
    }
    &-wrapper{
        @media #{$xxxxl,$xxxl} {
            padding-left: 80px;
        }
        @media #{$xxl} {
            padding-left: 100px;
        }
        @media #{$xl} {
            padding-left: 102px;
        }
        @media #{$md} {
            padding-left: 30px;
        }
        @media #{$sm, $xs} {
            padding-left: 0;
            margin-top: 160px;
        }
        &-thumb{
            margin-left: -25px;
            @media #{$lg,$md,$sm,$xs} {
                margin-left: 0;
            }
            & img{
                @media #{$lg,$md,$sm,$xs} {
                    max-width: 100%;
                }
            }
        }
    }
    &-shape{
        & .shape-1{
            position: absolute;
            left: 0px;
            top: -115px;
            z-index: -1;
            @media #{$xxxxl} {
                left: 0px;
            }
            @media #{$xxxl} {
                left: -25px;
            }
            @media #{$xxl} {
                left: -145px;
            }
            @media #{$xl} {
                left: 0px;
            }
        }
    }
    &-item{
        position: relative;
        &:hover{
            & .tp-about-item-thumb{
                & img{
                    transform: scaleX(-1);
                }
            }
        }
        &::after {
            position: absolute;
            content: '';
            top: 0;
            right: -25px;
            width: 1px;
            height: 248px;
            border-right: 1px dashed #D3D6DA;
            z-index: 6;
            @media #{$xxxxl} {
                right: -15px;
            }
            @media #{$xxxl} {
                right: -2px;
            }
            @media #{$xl} {
                right: 20px;
            }
            @media #{$lg} {
                right: 15px;
            }
            @media #{$sm} {
                right: -12px;
            }
          }
        &-thumb{
            margin-bottom: 27px;
            & img{
                height: 61px;
                width: 61px;
                transition: transform 0.5s ease;
            }
            @media #{$sm, $xs} {
                display: flex;
                justify-content: center;
            }
        }
        &-content{
            & .about-title{
                font-family: var(--tp-ff-poppins);
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                text-transform: capitalize;
                color: var(--tp-heading-secondary);
                margin-bottom: 15px;
            }
            & p{
                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
                color: var(--tp-text-body);
                & br{
                    @media #{$xxxxl,$xxxl,$xxl,$md,$sm} {
                        display: none;
                    }
                }
            }
            &-nav {
                display: none;
                @media #{$sm, $xs} {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    gap: 10px;
                    margin-bottom: 40px;
                }
            }
            @media #{$sm, $xs} {
                text-align: center;
            }
        }
        &-btn{
           & a {
                height: 50px;
                width: 50px;
                border: 1px solid #E8EBEE;
                display: inline-block;
                text-align: center;
                line-height: 48px;
                border-radius: 50%;
                color: var(--tp-theme-primary);
                & i{
                    @include transition (.3s);
                }
                &:hover i{
                    animation: tfLeftToRight 0.5s forwards;
                    color: var(--tp-theme-secondary);
                }
              }
        }
    }
    &-title-wrapper{
        margin-bottom: 80px;
        @media #{$sm,$xs} {
            margin-bottom: 50px;
        }
    }
    &-call{
        border: 1px solid #E6EDF2;
        border-radius: 28px;
        padding: 12px 17px;
        width: 643px;
        margin: auto;
        margin-top: 12px;
        @media #{$sm,$xs} {
            width: 100%;
        }
        & a{
           & p{
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: var(--tp-heading-secondary);
            margin-bottom: 0;
            & span{
                margin-left: 18px;
                text-decoration: underline;
                transition: all .3s;
                &:hover{
                    color: var(--tp-theme-secondary);
                }
            }
            & i{
                height: 28px;
                width: 28px;
                line-height: 28px;
                border-radius: 50%;
                text-align: center;
                margin-right: 10px;
                display: inline-block;
                color: var(--tp-common-white);
                background: var(--tp-theme-secondary);
                transform: translateY(1px);
            }
           }
        }
    }
    &-nav{
        & button{
            position: absolute;
            bottom: -100px;
            left: 10%;
            height: 51px;
            width: 55px;
            font-size: 16px;
            font-weight: 500;
            color: var(--tp-heading-secondary);
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.01);
            transition:all .3s ease-in-out;
            z-index: 1;
            @media #{$md,$sm,$xs} {
                display: none;
            }
            &:hover{
                color: var(--tp-common-white);
                background: var(--tp-heading-secondary);
            }
            &.about-button-prev-1{
                left: -25px;
            }

        }
    }
    &-breadcrumb{
        @media #{$sm,$xs} {
            padding-top: 30px;
        }
        &-img{
            & img{
                @media #{$xs} {
                    width: 100%;
                }
            }
        }
    }

    &-3{
        &-area{
            overflow: hidden;
            & .shape-bg{
                position: absolute;
                left: -200px;
                top: 0;
                z-index: -1;
            }
        }
        &-shape {
            position: absolute;
            top: 35%;
            left: 0;
            @media #{$lg,$md,$sm,$xs} {
                display: none;
            }
          }
        &-img{
            @media #{$md,$sm,$xs} {
                padding-bottom: 110px;
            }
            & img{
                border-radius: 7px;
                @media #{$xs} {
                    max-width: 100%;
                }
            }
            & .shape-1{
                position: absolute;
                top: 45px;
                right: 130px;
                @media #{$lg} {
                    right: 0px;
                }
                @media #{$md} {
                    right: 30%;
                }
                @media #{$sm} {
                    top: 32px;
                    right: 30px;
                }
                @media #{$xs} {
                    display: none;
                }
            }
            & .shape-2{
                position: absolute;
                bottom: -167px;
                right: 130px;
                & .tp-video-play{
                    & a{
                        height: 77px;
                        width: 77px;
                        line-height: 80px;
                        font-size: 22px;
                        cursor: pointer;
                        &::after{
                            height: 94px;
                            width: 94px;
                            top: -7px;
                            left: -7px;
                            z-index: -1;
                        }
                    }
                }
                @media #{$lg} {
                    right: 0px;
                }
                @media #{$md} {
                    right: 5%;
                    bottom: 9%;
                }
                @media #{$sm} {
                    bottom: 25px;
                    right: 0;
                }
                @media #{$xs} {
                    display: none;
                }
            }
            & .shape-3{
                position: absolute;
                top: 40%;
                right: 28%;
                @media #{$lg} {
                    top: 28%;
                    right: 0;
                }
                @media #{$md} {
                    top: 25%;
                    right: 33%;
                }
                @media #{$sm} {
                    top: 26%;
                    right: 14%;
                }
                @media #{$xs} {
                    display: none;
                }
            }
            & .shape-4{
                position: absolute;
                left: -7px;
                bottom: -116px;
                z-index: 1;
                @media #{$md} {
                    left: -15px;
                    bottom: 60px;
                }
                @media #{$sm} {
                    bottom: 50px;
                }
                @media #{$xs} {
                    right: 8px;
                    bottom: 25px;
                    left: auto;
                }
            }
            & .shape-5{
                position: absolute;
                bottom: -115px;
                left: -5px;
                z-index: 0;
                animation: tprotate 10s ease-in-out 5s forwards infinite alternate;
                @media #{$md} {
                    left: -15px;
                    bottom: 60px;
                }
                @media #{$sm} {
                    bottom: 50px;
                }
                @media #{$xs} {
                    right: 8px;
                    bottom: 28px;
                    left: auto;
                }
            }
        }
        &-wrapper{
            & .tp-about-3-title-wrapper{
                & .tp-section-title{
                    margin-bottom: 40px;
                }
            }
            & .text{
                position: relative;
                font-weight: 400;
                font-size: 16px;
                color: #535353;
                margin-left: 13px;
                & br{
                    @media #{$lg,$md,$sm,$xs} {
                        display: none;
                    }
                }
                &::after{
                    position: absolute;
                    top: 7px;
                    left: -13px;
                    content: '';
                    width: 2px;
                    height: 72px;
                    background: var(--tp-theme-secondary);
                    @media #{$xs} {
                        display: none;
                    }
                }
            }
        }
        &-progressbar{
            &:not(:last-of-type){
                margin-right: 42px;
                @media #{$xl} {
                    margin-right: 20px;
                }
                @media #{$xs} {
                    margin-right: 10px;
                }
            }
            margin-bottom: 55px;
            @media #{$lg,$md,$sm,$xs} {
                margin-bottom: 25px;
            }
            & .circular{
                width: 100px; 
                & .knob{
                    font-family: var(--tp-ff-dm-sans) !important;
                    color: var(--tp-theme-secondary) !important;
                }
            }
            &-title{
                & p{
                    font-weight: 500;
                    font-size: 18px;
                    margin-left: 28px;
                    color: var(--tp-heading-secondary);
                    @media #{$xl} {
                        margin-left: 20px;
                    }
                }
            }
        }
        &-btn-inner{
            & .tp-about-btn {
                margin-right: 56px;
                @media #{$lg} {
                    margin-right: 20px;
                }
                & .tp-btn {
                    text-transform: uppercase;
                    padding: 24px 32px;
                }
            }
        }
        &-year{
            & p{
                font-weight: 500;
                font-size: 16px;
                color: var(--tp-heading-secondary);
                @media #{$xs} {
                    margin-top: 15px;
                }
                & span{
                    font-weight: 400;
                    font-size: 16px;
                    color: #535353;
                }
            }
        }
    }
}


.tp-video{
    &-breadcrumb{
        & img{
            max-width: 100%;
        }
    }
    &-play{
        position: absolute;
        top: 40%;
        left: 50%;
        z-index: auto;
        & a{
            height: 119px;
            width: 119px;
            font-weight: 900;
            font-size: 24px;
            line-height: 122px;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
            transform: translateX(-50%);
            color: var(--tp-theme-secondary);
            background: var(--tp-common-white);
            box-shadow: 0px 0px 50px rgba(3, 4, 28, 0.1);
            z-index: 2;
            cursor: pointer;
            &::after {
                position: absolute;
                content: '';
                height: 146px;
                width: 146px;
                top: -13px;
                left: -13px;
                background: rgba(255, 255, 255, 0.28);
                border-radius: 50%;
                animation: pulse 2s infinite;
                z-index: -1;
              }
            &:hover{
                background: var(--tp-theme-secondary);
                & i{
                    color: var(--tp-common-white);
                }
            }
            @media #{$md} {
                top: 28%;
            }
            @media #{$sm,$xs} {
                display: none;
            }
        }
    }
}

// Progressbar 
.CircularProgressbar .CircularProgressbar-path {
    stroke: #EF5A32 !important;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
}
.CircularProgressbar .CircularProgressbar-text {
    fill: #EF5A32 !important; 
}