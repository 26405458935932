@use '../utils' as *;

/*----------------------------------------*/
/*  2.10 Offcanvas
/*----------------------------------------*/


.offcanvas{
    $self : &;
    &__area{
        position: fixed;
        left: 0;
        top: 0;
        width: 400px;
        height: 100%;
        @include transform(translateX(calc(-100% + -80px)));
        background: var(--tp-common-white) none repeat scroll 0 0;
        transition: all .4s ease-in-out;
        opacity: 0;
        visibility: hidden;
        z-index: 999;
        
        overflow-y: scroll;
        overscroll-behavior-y: contain;
        scrollbar-width: none;

        @media #{$lg,$md,$sm,$xs} {
            right: 0;
            left: auto;
            @include transform(translateX(calc(100% + 80px)));
        }

        &.home-2-pos{
            @media #{$xl} {
                right: 0;
                left: auto;
                @include transform(translateX(calc(100% + 80px)));
            }
        }
        &.home-3-pos{
            right: 0;
            left: auto;
            @include transform(translateX(calc(100% + 80px)));
        }

        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
        &.offcanvas-opened{
            @include transform(translateX(0));
            visibility: visible;
            opacity: 1;
        }

        @media #{$xs}{
            width: 100%;
        }

    }
    &__top{
        @media #{$md, $sm, $xs}{
            margin-bottom: 30px;
        }
    }
    &__wrapper{
        position: relative;
        padding: 40px;
        z-index: 1;
        min-height: 100%;

        @media #{$xs}{
           padding: 20px;
        }
    }
    &__close{
        position: absolute;
        top: 35px;
        right: 30px;

        @media #{$xs}{
            right: 20px;
            top: 20px;
        }
        &-btn{
            display: inline-block;
            font-size: 16px;
            height: 44px;
            width: 44px;
            line-height: 40px;
            background-color: #f5f5f5;
            color: var(--tp-text-1);
            & svg{
                transition: all .3s ease-in-out;
            }
            &:hover{
                & svg{
                    transform: rotate(45deg)
                }
            }

            & svg{
                @include transform(translateY(-1px));
            }
        }
    }
    &__inner{
        & h4{
            font-family: var(--tp-ff-space);
            font-size: 24px;
            color: var(--tp-common-white);
            line-height: 1.3;
            margin-bottom: 15px;
        }
        & p{
            font-family: var(--tp-ff-inter);
            font-size: 16px;
            line-height: 22px;
            color: var(--tp-common-white);
            margin-bottom: 30px;
        }
        & > img{
            margin-bottom: 30px;
        }
    }
    &__menu{
        & ul{
            & li{
                list-style: none;
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
                & a{
                    font-weight: 700;
                    font-size: 20px;
                    color: var(--tp-common-black);
                }

                &:hover{
                    & > a{
                        color: var(--tp-theme-primary);
                    }
                }
            }
        }
    }
    &__text{
        & p{
            font-family: var(--tp-ff-inter);
            font-weight: 400;
            font-size: 14px;
            line-height: 1.7;
            letter-spacing: 0.01em;
            color: var(--tp-common-black-4);
            margin-bottom: 25px;
        }
    }
    &__contact{
        margin-bottom: 30px;
        &-content{
            margin-bottom: 10px;
            &-icon{
                & i{
                    color: #6EC1E4;
                    margin-right: 10px;
                }
            }
            &-content{
                & a{
                    font-size: 16px;
                }
            }
        }
    }
    &__social{
        margin-bottom: 22px;
        padding-bottom: 40px;
        & .icon{
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: transparent;
            color: var(--tp-common-white);
            margin-right: 5px;
            transition: all .3s ease-in-out;
            &.facebook{
                background-color: #4661C5;
            }
            &.twitter{
                background-color: #4CCEF9;
            }
            &.youtube{
                background-color: #FF0000;
            }
            &.linkedin{
                background-color: #0072b1;
            }
            &:hover{
                transform: translateY(-8px);
            }
        }
    }
    &__title{
        font-size: 24px;
        color: #1b1d21;
        font-weight: 700;
        margin-bottom: 10px;
    }
    &__popup{
        @media #{$lg,$md,$sm,$xs} {
            display: none;
        }
        & p{
            margin-bottom: 20px;
        }
        &-gallery{
            margin-bottom: 30px;
            & a{
                margin: 5px 3px;
                display: inline-block;
                & img{
                    height: 100px;
                    max-width: 100px;
                    object-fit: cover;
                }
            }
        }
    }
    &__info{
        &-item{
            margin-bottom: 38px;
            &-title{
                font-family: var(--tp-ff-space);
                font-weight: 700;
                font-size: 18px;
                color: var(--tp-common-white);
                margin-bottom: 12px;
            }

            & p{
                font-size: 16px;
                color: var(--tp-text-16);
                margin-bottom: 0;
                line-height: 22px;

                & a{
                    &:hover{
                        color: var(--tp-theme-primary);
                    }
                }
            }
        }
    }
}