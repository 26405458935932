.header {
    padding: 0 53px;

    @media (max-width: 1200px) {
        padding: 0;
    }

    &_hamburger {
        @media (min-width: 1200px) {
            display: none;
        }
    }

    &_log-in-btn {
        background-color: var(--tp-common-white);
        color: var(--tp-theme-secondary);
        border: 2px solid var(--tp-theme-secondary);
        box-sizing: border-box;
        padding: 17px;

        &:hover {
            background-color: var(--tp-theme-secondary);
            color: var(--tp-common-white);
        }
    }

    &_log-in-icon {
        margin-left: 0 !important;
        font-weight: 500;
        font-size: 22px;
    }
}

.pd-r-l {
    margin: 0 53px;

    @media (max-width: 1200px) {
        margin: 0;
    }
}

.slider {
    &_btn {
        position: relative;
        z-index: 1;
        overflow: hidden;
        font-family: var(--tp-ff-dm-sans);
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        padding: 21px 48px;
        text-align: center;
        letter-spacing: -0.01em;
        text-transform: capitalize;
        display: inline-block;
        color: var(--tp-common-white);
        background: var(--tp-theme-primary);
        &::after {
            position: absolute;
            content: "";
            background: var(--tp-theme-third);
            height: 34px;
            width: 36px;
            border-radius: 15px;
            right: -11px;
            bottom: -11px;
        }
        &::before {
            content: "";
            position: absolute;
            height: 400px;
            width: 430px;
            top: 50%;
            left: 50%;
            background: var(--tp-theme-third);
            border-radius: 50%;
            transform: translateX(-22%) translateY(-42%) scale(0);
            -moz-transition: all 0.5s ease-out 0s;
            -webkit-transition: all 0.5s ease-out 0s;
            -ms-transition: all 0.5s ease-out 0s;
            -o-transition: all 0.5s ease-out 0s;
            transition: all 0.5s ease-out 0s;
            z-index: -1;
        }
        &:hover {
            color: var(--tp-theme-primary);
            &::before {
                transform: translateX(-50%) translateY(-50%) scale(1);
            }
        }
        & i {
            margin-left: 16px;
            transform: translateY(2px);
        }
    }

    &_arrow {
        height: 66px;
        width: 66px;

        &-next {
            border-radius: 50%;
        }

        &-prev {
            border-radius: 50%;
        }
        // &-next img {
        //     left: 26px !important;
        //     top: 15px !important;
        // }

        // &-prev img {
        //     left: -26px !important;
        //     top: 15px !important;
        // }

        &:hover {
            background-color: var(--tp-border-primary);
        }

        @media (max-width: 980px) {
            height: 35px;
            width: 35px;
        }
    }

    &_dot {
        height: 33px;
        width: 33px;

        &:hover {
            background-color: var(--tp-bg-light-yellow);
        }

        @media (max-width: 980px) {
            height: 18px;
            width: 18px;
        }
    }
}

.about {
    padding: 0 80px;

    @media (max-width: 1200px) {
        padding: 0;
    }

    &_subtitle {
        font-size: 1.5rem;
        letter-spacing: 2px;
        font-family: var(--tp-ff-poppins);
        & svg {
            bottom: -14px;
        }
        @media (max-width: 1200px) {
            margin-top: 3rem;
            font-size: 1.2rem !important;
        }
        @media (max-width: 600px) {
            font-size: 1.2rem;
            text-align: center;
            width: 100%;
            margin-top: 1rem;
            & svg {
                left: calc(50% - 90px);
            }
        }
    }

    &_header {
        font-size: 2.35rem;

        @media (max-width: 600px) {
            font-size: 2rem;
        }
    }

    &_title {
        color: var(--tp-theme-secondary);
    }

    &_description {
        font-size: 1.1rem !important;
    }

    &_img {
        @media (max-width: 600px) {
            display: none;
        }
    }
}

.services {
    &_right-shape {
        position: absolute;
        right: 110px;
        bottom: 0;
        @media (max-width: 600px) {
            display: none;
        }
    }

    &_center-shape {
        position: absolute;
        bottom: -30px;
        left: calc(50% - 215px);

        @media (max-width: 600px) {
            display: none;
        }

        & svg {
            @media (max-width: 600px) {
                width: 130px;
            }
        }

        @media (max-width: 600px) {
            left: calc(50% - 140px);
            bottom: -20px;
        }
    }

    &_text {
        color: var(--tp-theme-third);
    }

    &_arrow {
        background-color: var(--tp-theme-primary);
        border: 1px solid var(--tp-theme-third);
        height: 60px;
        width: 60px;

        & svg {
            fill: var(--tp-theme-third);
        }

        &:hover {
            background-color: var(--tp-grey-dark);
        }
    }
}

.partners {
    &-title-wrapper {
        & .tp-section-title__pre {
            color: var(--tp-theme-primary);
        }
        & .tp-section-title {
            color: var(--tp-theme-primary);
        }
    }

    @media (max-width: 600px) {
        font-size: 2rem;
    }

    &-title-secondary {
        color: var(--tp-theme-secondary);
    }

    &_center-shape {
        position: absolute;
        bottom: -30px;
        left: 51%;

        @media (max-width: 600px) {
            bottom: -25px;
            right: 0;
        }

        & svg {
            @media (max-width: 600px) {
                width: 140px;
            }
        }
    }
}

.slider {
    position: relative;
}

.slider::before,
.slider::after {
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 0) 100%
    );
    content: "";
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
}

.slider::before {
    left: 0;
    top: 0;
}

.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.slide-track {
    display: flex;
    width: calc(400px * 20); // 20 = number of items in data (Partners) * 2
    animation: scroll 40s linear infinite;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(
            calc(-400px * 10)
        ); // 10 = number of items in data (Partners)
    }
}

.image-slide {
    display: flex;
    align-items: center;
    padding: 0 40px;
}

.appbar {
    flex: 0 0 auto;
    width: 12%;

    &-container {
        justify-content: space-between;
    }
}

.swiper {
    @media (max-width: 768px) {
        overflow: unset;
    }
}

#__next {
    @media (max-width: 768px) {
        overflow-x: hidden;
    }
}

.testimonials {
    &-shape {
        left: calc(50% + 70px);

        @media (max-width: 600px) {
            left: calc(50% + 20px);
            width: 130px;
        }
    }
}

.contact {
    &-title {
        width: fit-content;
        @media (max-width: 600px) {
            font-size: 1.6rem;
            width: 100%;
        }
    }

    &-header {
        @media (max-width: 600px) {
            margin: 30px;
        }
    }
}

.company-type {
    z-index: 6;
}

.download-pdf {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.video-area {
    &-wrapper {
        position: relative;
    }

    &-image {
        position: relative;
        width: 100%;
        height: 500px;

        @media (min-width: 2000px) {
            height: 580px;
        }

        @media (max-width: 600px) {
            height: 200px;
        }
    }

    &-video {
        position: absolute;
        width: 100%;
    }

    &-text {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -30%);
        z-index: 1000;
        font-family: var(--tp-ff-raleway);
        text-align: center;
        width: 100%;

        @media (max-width: 600px) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &-secondary {
            color: #000;
            font-size: 34px;
            font-weight: 500;

            @media (max-width: 600px) {
                display: none;
            }
        }

        &-primary {
            color: #fff;
            font-size: 46px;
            font-weight: 900;
            margin-top: 80px;
            letter-spacing: 3px;
            line-height: 1.4;

            @media (max-width: 600px) {
                font-size: 20px;
                font-weight: 800;
                margin-top: 0;
                letter-spacing: 2px;
                line-height: 1.2;
            }
        }
    }
}

.platform-videos-area {
    border: 1px solid var(--tp-common-black);
    border-radius: 10px;
    margin: 40px 80px 0 80px;

    @media (max-width: 600px) {
        border: none;
        margin: 40px 20px 0 20px;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 40px;
        padding: 40px;

        @media (max-width: 600px) {
            display: block;
            padding: 0;
        }
    }

    &__block {
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 600px) {
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 18px;
            margin-top: 24px;
        }

        &-title {
            color: var(--tp-theme-blue);
            font-family: var(--tp-ff-raleway);
            font-weight: 500;
            font-size: 1.3rem;

            @media (max-width: 600px) {
                font-size: 0.86rem;
                font-weight: 600;
                margin: 0;
            }

            &-desktop {
                display: block;
                text-align: center;
                @media (max-width: 600px) {
                    display: none;
                }
            }

            &-mobile {
                display: none;
                @media (max-width: 600px) {
                    display: block;
                }
            }
        }

        &-content {
            width: 100% !important;

            @media (max-width: 600px) {
                display: flex !important;
                flex-direction: column-reverse !important;
                justify-content: center !important;
                align-items: center !important;
                gap: 20px !important;
            }
        }

        &-title {
            color: rgb(127, 127, 127);
            margin-bottom: 0;
            font-size: 30px;

            @media (max-width: 600px) {
                font-size: 24px;
                text-align: center;
            }
        }

        &-subtitle {
            font-size: 22px;
            color: rgb(89, 89, 89);

            @media (max-width: 600px) {
                width: 100%;
                font-size: 18px;
                text-align: center;
            }
        }

        &-svg svg {
            position: absolute;
            left: 0;
            bottom: -12px;

            @media (max-width: 600px) {
                display: none;
            }
        }

        &-svg2 svg {
            position: absolute;
            right: -34px;
            bottom: -12px;
            width: 116px;

            @media (max-width: 600px) {
                display: none;
            }
        }

        &-svg3 svg {
            position: absolute;
            left: 22%;
            bottom: -12px;

            @media (max-width: 600px) {
                display: none;
            }
        }

        &-image {
            border: 1px solid var(--tp-theme-secondary);
            border-radius: 10px;
            width: 100%;
            height: 260px;

            @media (max-width: 600px) {
                width: 100%;
                height: 160px;
                margin-bottom: 20px;
            }

            &-wrapper {
                position: absolute;
                bottom: 0;

                @media (min-width: 601px) {
                    display: inline-block;
                    overflow: hidden;
                }
            }

            &-wrapper img {
                @media (min-width: 601px) {
                    display: block;
                    transition: transform 0.5s;
                }
            }

            &-wrapper:hover img {
                @media (min-width: 601px) {
                    transform: scale(1.2);
                }
            }
        }

        &-image-2 {
            &-wrapper {
                position: absolute;
                top: 0;
                height: 160px;

                @media (min-width: 601px) {
                    display: inline-block;
                    overflow: hidden;
                }
            }

            &-wrapper img {
                @media (min-width: 601px) {
                    display: block;
                    transition: transform 0.5s;
                }
            }

            &-wrapper:hover img {
                @media (min-width: 601px) {
                    transform: scale(1.2);
                }
            }
        }

        &-description {
            font-family: var(--tp-ff-dm-sans);
            font-size: 1.2rem;
            margin-top: 10px;
            overflow-wrap: break-word;
            color: var(--tp-common-black);
            text-align: center;
            padding: 0 15%;

            @media (max-width: 600px) {
                width: 100%;
                font-size: 0.86rem;
                text-align: left;
                font-weight: 500;
                margin: 0;
                line-height: 1.5;
                padding: 0;
            }
        }
    }
}

.video-play {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: auto;
    & a {
        height: 70px;
        width: 70px;
        font-weight: 900;
        font-size: 24px;
        line-height: 122px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        transform: translate(-50%, -50%);
        color: var(--tp-common-white);
        border: 2px solid var(--tp-theme-secondary);
        background: var(--tp-theme-third);
        box-shadow: 0px 0px 50px rgba(3, 4, 28, 0.1);
        z-index: 2;
        cursor: pointer;
        opacity: 0.9;

        @media (max-width: 600px) {
            height: 40px;
            width: 40px;
            font-size: 18px;
        }

        & i {
            position: relative;
            top: -25px;
            right: -2px;

            @media (max-width: 600px) {
                top: -41px;
            }
        }

        &:hover {
            animation: pulse 2s infinite;
        }

        &::after {
            position: absolute;
            content: "";
            height: 75px;
            width: 75px;
            top: -4px;
            left: -4px;
            background: rgba(255, 255, 255, 0.28);
            border-radius: 50%;
            z-index: -1;

            @media (max-width: 600px) {
                height: 42px;
                width: 42px;
            }
        }
    }
}

.comprehensive-speed {
    height: fit-content;

    &-wrapper {
        height: 100%;
    }

    &-shape {
        & .shape-1 {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 0;

            @media (max-width: 600px) {
                display: none;
            }
        }
        & .shape-2 {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;

            @media (max-width: 600px) {
                display: none;
            }
        }
    }

    &-content {
        display: flex;
        justify-content: center;
        gap: 120px;
        align-items: center;
        height: 100%;
        width: 70%;
        margin: auto;
        padding: 40px 0;

        @media (max-width: 600px) {
            display: block;
            padding: 30px;
            width: 100%;
        }
    }

    &-text {
        color: #bfbfbf;

        &-title {
            font-size: 32px;

            @media (max-width: 600px) {
                font-size: 24px;
                text-align: start;
            }
        }
    }

    &-title {
        &-svg svg {
            width: 120px;
            position: absolute;
            bottom: -8px;
            left: 58%;

            @media (max-width: 600px) {
                right: 0;
                left: unset;
            }
        }
    }

    &-svg svg {
        left: 86%;
    }

    &-image {
        height: 400px;
        border-radius: 20px;

        @media (max-width: 600px) {
            width: 100%;
            height: 180px;
            border-radius: 10px;
        }

        &-wrapper {
            position: relative;
            @media (min-width: 601px) {
                display: inline-block;
                overflow: hidden;
            }
        }

        &-wrapper img {
            @media (min-width: 601px) {
                display: block;
                transition: transform 0.5s;
            }
        }

        &-wrapper:hover img {
            @media (min-width: 601px) {
                transform: scale(1.2);
            }
        }
    }
}

.grecaptcha-badge {
    visibility: hidden !important;
}

.captcha-msg {
    z-index: 1;
}

// .video-area {
//     &-wrapper {
//         position: relative;
//     }

//     &-image {
//         position: relative;
//         width: 100%;
//         height: 500px;

//         @media (min-width: 2000px) {
//             height: 580px;
//         }

//         @media (max-width: 600px) {
//             height: 200px;
//         }
//     }

//     &-video {
//         position: absolute;
//         width: 100%;
//     }

//     &-text {
//         position: absolute;
//         top: 30%;
//         left: 50%;
//         transform: translate(-50%, -30%);
//         z-index: 1000;
//         font-family: var(--tp-ff-raleway);
//         text-align: center;
//         width: 100%;

//         @media (max-width: 600px) {
//             top: 50%;
//             left: 50%;
//             transform: translate(-50%, -50%);
//         }

//         &-secondary {
//             color: #000;
//             font-size: 34px;
//             font-weight: 500;

//             @media (max-width: 600px) {
//                 display: none;
//             }
//         }

//         &-primary {
//             color: #fff;
//             font-size: 46px;
//             font-weight: 900;
//             margin-top: 80px;
//             letter-spacing: 3px;
//             line-height: 1.4;

//             @media (max-width: 600px) {
//                 font-size: 20px;
//                 font-weight: 800;
//                 margin-top: 0;
//                 letter-spacing: 2px;
//                 line-height: 1.2;
//             }
//         }
//     }
// }
