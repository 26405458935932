@use "../../utils" as *;

/*----------------------------------------*/
/*  7.5 feature css
/*----------------------------------------*/

.tp-service {
    &-funfact-box {
        background: var(--tp-theme-primary);
        position: relative;
        z-index: 1;
    }
    &-area {
    }
    &-title-wrapper {
        & .tp-section-title__pre {
            color: var(--tp-common-white);
            font-family: var(--tp-ff-poppins);
            letter-spacing: 2px;
            font-weight: 400;
            font-size: 1.5rem;
            @media #{$sm, $xs} {
                font-size: 1.2rem;
            }
        }
        & .tp-section-title {
            color: var(--tp-common-white);
            @media #{$sm, $xs} {
                font-size: 2rem;
            }
        }
    }
    &-breadcrumb {
        &-title-wrapper {
            & p {
                & br {
                    @media #{$lg,$md,$sm,$xs} {
                        display: none;
                    }
                }
                @media #{$md,$sm,$xs} {
                    margin-bottom: 40px;
                }
            }
        }
    }
    &-slider-wrapper {
        margin: 0 auto;
        max-width: 90%;
    }
    &-wrapper {
        position: relative;
        width: 100%;
        background: var(--tp-grey-light);
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        border: 1px solid var(--tp-grey-light);
        margin-bottom: 10px;
        &:not(.collapsed) {
            background: var(--tp-grey-dark2);
        }
        &:hover {
            border: 1px solid var(--tp-theme-secondary);
        }
        & .service-title {
            font-weight: 500;
            font-size: 22px;
            color: var(--tp-common-white);
            position: relative;
            z-index: 1;
        }
        & .hide-text {
            position: absolute;
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: var(--tp-common-white);
            margin-bottom: 0;
            visibility: hidden;
            opacity: 0;
            top: 43%;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            padding: 0 30px;
            transition: all 0.3s ease-in-out;
        }
        & .tp-service-btn {
            & i {
                transform: rotate(-90deg);
            }
        }
        & .collapsed .tp-service-btn {
            & i {
                transform: rotate(90deg);
            }
        }
    }
    &-designation {
        & p {
            font-family: var(--tp-ff-poppins);
            font-weight: 500;
            font-size: 18px;
            color: var(--tp-common-white);
            border: 1px solid rgba(230, 237, 242, 0.15);
            border-radius: 50%;
            display: inline-block;
            line-height: 52px;
            width: 52px;
            height: 52px;
            position: absolute;
            right: 21px;
            top: 18px;
            z-index: 1;
        }
    }
    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: var(--bs-accordion-btn-padding-x);
    }
    &-description {
        display: flex;
        align-items: center;
        color: var(--tp-common-white);
        margin-left: 66px;
        font-size: 16px;
    }
    &-btn {
        font-size: 14px;
        color: var(--tp-theme-third);
        z-index: 1;
        position: relative;
        & i {
            margin-left: 10px;
            transition: all 0.1s linear;
            transform: translateY(2px);
        }
    }
    &-link-btn {
        margin-left: auto;
        & .tp-btn {
            padding: 21px 51px;
            border: 1px solid var(--tp-grey-light);
            background-color: var(--tp-common-black);
        }
    }

    @media #{$sm,$xs} {
        text-align: center;
        &-title {
            flex-direction: column;
            text-align: center;
        }
        &-icon {
            padding-right: 0;
            padding-bottom: var(--bs-accordion-btn-padding-y);
        }
        &-description {
            margin-left: 0;
            flex-direction: column;
        }
        &-link-btn {
            margin: 10px auto;
        }
    }

    &-3 {
        &-area {
            background: var(--tp-theme-primary);
            overflow: hidden;
        }
        &-title-wrapper {
            & .tp-section-title {
                color: var(--tp-common-white);
                &__pre {
                    color: var(--tp-common-white);
                }
            }
            & p {
                font-weight: 400;
                font-size: 16px;
                color: #9c9c9c;
                @media #{$lg,$md,$sm,$xs} {
                    margin-bottom: 40px;
                }
                & br {
                    @media #{$xs} {
                        display: none;
                    }
                }
            }
        }
        &-content {
            position: relative;
            background: #353535;
            border: 1px solid rgba(255, 255, 255, 0.1);
            padding: 58px 40px 36px 39px;
            &.breadcrumb-item {
                background-color: var(--tp-common-white);
                border: 1px solid rgba(181, 182, 196, 0.28);
                transition: all 0.3s ease-in-out;
                &:hover {
                    background-color: var(--tp-theme-primary);
                    & .tp-service-breadcrumb-title {
                        color: var(--tp-theme-secondary);
                    }
                }
                & .tp-service-3-content-thumb {
                    &::before {
                        background: rgba(6, 218, 195, 0.1);
                    }
                }
            }
            &::after {
                content: "";
                position: absolute;
                top: -1px;
                right: -1px;
                clip-path: polygon(29% 0, 100% 71%, 100% 0);
                background: var(--tp-theme-secondary);
                height: 42px;
                width: 0px;
                transition: all 0.4s;
            }
            &:hover {
                &::after {
                    width: 42px;
                }
            }
            &-thumb {
                position: relative;
                &::before {
                    position: absolute;
                    content: "";
                    top: -15px;
                    left: 25px;
                    height: 46px;
                    width: 46px;
                    background: rgba(255, 255, 255, 0.09);
                    border-radius: 50%;
                }
                & img {
                    margin-bottom: 30px;
                    width: 44px;
                    height: 44px;
                }
            }
            & p {
                font-weight: 400;
                font-size: 16px;
                color: #9c9c9c;
                margin-bottom: 22px;
            }
            & .tp-service-btn {
                & a {
                    &:hover {
                        & i {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
        &-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-transform: capitalize;
            color: var(--tp-common-white);
            margin-bottom: 20px;
            & a:hover {
                color: var(--tp-theme-secondary);
            }
        }
        &-trend {
            & p {
                font-weight: 500;
                font-size: 15px;
                line-height: 36px;
                letter-spacing: 0.15em;
                text-transform: uppercase;
                color: var(--tp-common-white);
                margin-bottom: 0;
                & a {
                    color: var(--tp-theme-secondary);
                }
                & i {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }
    }
    &-breadcrumb-area {
        overflow: hidden;
    }
}

.tp-service {
    &-details-area {
    }
    &-breadcrumb {
        &-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            transition: none;
        }
    }
    &-details {
        &-wrapper {
            & p {
                font-weight: 400;
                font-size: 16px;
                line-height: 31px;
                color: #5a627d;
                margin-bottom: 42px;
            }
        }
        &-title {
            font-weight: 600;
            font-size: 28px;
            text-transform: capitalize;
            color: var(--tp-heading-secondary);
            margin-bottom: 15px;
        }
        &-thumb {
            margin-bottom: 40px;
            & img {
                width: 100%;
                height: auto;
            }
            & .tp-video-play {
                & a {
                    height: 80px;
                    width: 80px;
                    line-height: 80px;
                    font-size: 20px;
                    top: 39%;
                    left: 48%;
                    &::after {
                        height: 94px;
                        width: 94px;
                        top: -7px;
                        left: -7px;
                        z-index: -1;
                    }
                }
            }
        }
        &-list {
            & p {
                margin-bottom: 15px;
            }
            & ul {
                & li {
                    list-style: none;
                    margin-bottom: 14px;
                    & span {
                        color: var(--tp-theme-secondary);
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    &-widget {
        position: sticky;
        top: 100px;
        @media #{$md,$sm,$xs} {
            position: inherit;
        }
        &-tab {
            & ul {
                & li {
                    list-style: none;
                    margin-bottom: 13px;
                    & .active {
                        background-color: var(--tp-theme-primary);
                        color: var(--tp-common-white);
                        & i {
                            background-color: var(--tp-theme-secondary);
                        }
                    }
                    & a {
                        position: relative;
                        font-family: var(--tp-ff-dm-sans);
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 21px;
                        color: #101c2a;
                        background: #f1fbfe;
                        display: inline-block;
                        width: 100%;
                        padding: 24px 20px;
                        transition: color 0.4s ease-in-out,
                            background-color 0.4s ease-in-out;
                        &:hover {
                            background-color: var(--tp-theme-primary);
                            color: var(--tp-common-white);
                            & i {
                                background-color: var(--tp-theme-secondary);
                            }
                        }
                        & i {
                            position: absolute;
                            top: 30%;
                            right: 7px;
                            transform: translateY(-10px);
                            height: 45px;
                            width: 45px;
                            text-align: center;
                            line-height: 45px;
                            transition: background-color 0.4s ease-in-out;
                        }
                    }
                }
            }
        }
    }
    &-contact {
        &-form {
            background: #f8f8f8;
            padding: 80px 30px 65px;
            @media #{$xs} {
                padding: 60px 30px 50px;
            }
        }
        &-input {
            margin-bottom: 19px;
            & input {
                width: 100%;
                height: 60px;
                border: 1px solid #efeff2;
                background-color: var(--tp-common-white);
                outline: 0;
                color: var(--tp-common-black);
                padding: 0 25px;
                border-radius: 2px;
                &::placeholder {
                    font-family: var(--tp-ff-dm-sans);
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 10px;
                    color: #000d44;
                    opacity: 0.5;
                }
                &:focus {
                    border: 1px solid var(--tp-theme-secondary);
                    &::placeholder {
                        font-size: 0;
                    }
                }
            }
            & textarea {
                height: 167px;
                border: 1px solid #efeff2;
                background-color: var(--tp-common-white);
                resize: none;
                line-height: 3.5;
                border-radius: 2px;
                &::placeholder {
                    font-family: var(--tp-ff-dm-sans);
                    font-weight: 500;
                    font-size: 14px;
                    color: #000d44;
                    opacity: 0.5;
                }
                &:focus {
                    border: 1px solid var(--tp-theme-secondary);
                    &::placeholder {
                        font-size: 0;
                    }
                }
            }
        }
    }
}
