/*----------------------------------------*/
/*  2.4 Animations
/*----------------------------------------*/

/* pulse effect animation */

@-webkit-keyframes pulse {
	0% {
	  -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	}
	70% {
		-webkit-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}
@keyframes pulse {
0% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
}
70% {
	-moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
	box-shadow: 0 0 0 45px rgba(255,255,255, 0);
}
100% {
	-moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	box-shadow: 0 0 0 0 rgba(255,255,255, 0);
	}
}

// img-border
@keyframes sliderShape{

	0%,100%{
  
	border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  
	  transform: translate3d(0,0,0) rotateZ(0.01deg);
  
	}
  
	34%{
  
		border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
  
	  transform:  translate3d(0,5px,0) rotateZ(0.01deg);
  
	}
  
	50%{
  
	  transform: translate3d(0,0,0) rotateZ(0.01deg);
  
	}
  
	67%{
  
	  border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
  
	  transform: translate3d(0,-3px,0) rotateZ(0.01deg);
  
	}
  
  }

/* 04. ========= btn-arrow-animation ========== */
@keyframes tfLeftToRight {
	49% {
		transform: translateX(30%);
	}
	50% {
		opacity: 0;
		transform: translateX(-30%);
	}
	51% {
		opacity: 1;
	}
}


// tp up down animation
@keyframes tpupdown{
	0% { 
		transform: translateY(0);
	}
    100% { 
		transform: translateY(-20px);
	}
}

// tp circle animation
@keyframes circle-animation {
    0%{
     transform:rotate(-46deg);
    }
    100%{
         transform:rotate(314deg);
    }
}

@keyframes circle-animation3 {
    0%{
     transform:rotate(60deg);
    }
    100%{
         transform:rotate(420deg);
    }
}

@keyframes circle-animation2 {
	0%{
		transform:scale(-1, 1) rotate(198deg);
	}
	100%{
		transform: scale(-1, 1) rotate(-162deg);     
	}
}

@keyframes circle-animation8 {
	0%{
		transform:rotate(-50deg);
	}
	100%{
		transform: rotate(310deg);     
	}
}

@keyframes circle-animation88 {
	0%{
		transform: rotate(60deg);
	}
	100%{
		transform: rotate(420deg);     
	}
}

@keyframes circle-animation99 {
	0%{
		transform: rotate(60deg);
	}
	100%{
		transform: rotate(420deg);     
	}
}

@keyframes circle-6 {
	0%{
		transform:rotate(215deg);
	}
	100%{
		transform: rotate(575deg);     
	}
}






//header animation
@-moz-keyframes headerSlideDown {
	0% {
	 margin-top:-150px
	}
	100% {
	 margin-top:0
	}
   }
   @-ms-keyframes headerSlideDown {
	0% {
	 margin-top:-150px
	}
	100% {
	 margin-top:0
	}
   }
   @-webkit-keyframes headerSlideDown {
	0% {
	 margin-top:-150px
	}
	100% {
	 margin-top:0
	}
   }
   @keyframes headerSlideDown {
	0% {
	 margin-top:-150px
	}
	100% {
	 margin-top:0
	}
   }
   @-moz-keyframes headerSlideUp {
	0% {
	 margin-top:0
	}
	100% {
	 margin-top:-150px
	}
   }
   @-ms-keyframes headerSlideUp {
	0% {
	 margin-top:0
	}
	100% {
	 margin-top:-150px
	}
   }
   @-webkit-keyframes headerSlideUp {
	0% {
	 margin-top:0
	}
	100% {
	 margin-top:-150px
	}
   }
   @keyframes headerSlideUp {
	0% {
	 margin-top:0
	}
	100% {
	 margin-top:-150px
	}
   }

	// tp up down animtaion
	@keyframes section_stroke {
		0% {
			stroke-dashoffset: 560;
		}
		50% {
			stroke-dashoffset: 0;
		}
		100% {
			stroke-dashoffset: -560;
		}
	}

	// tp rotate animation
	@keyframes tprotate{
		0%{
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		}
		100%{
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
		}
	}

	// about text animation
	@keyframes about-text{
		0% {
			width: 0;
		}
		100% {
			width: 100%;
		}
	}


	// animation  icon-bounce
	@keyframes icon-bounce {
		0%,
		100%,
		20%,
		50%,
		80% {
		transform:translateY(0);
		}
		40% {
		transform:translateY(-10px);
		}
		60% {
		transform:translateY(-5px);
		}
	}


	// video effect
	@keyframes tp-hotspot-2{
		0% {
			-webkit-transform: scale(0.1);
			-moz-transform: scale(0.1);
			-ms-transform: scale(0.1);
			-o-transform: scale(0.1);
			transform: scale(0.1);
			opacity: 0;
		}
		70% {
			opacity: 1;
		}
		100% {
			-webkit-transform: scale(1.3);
			-moz-transform: scale(1.3);
			-ms-transform: scale(1.3);
			-o-transform: scale(1.3);
			transform: scale(1.3);
			opacity: 0;
		}
	}

	@keyframes tp-hotspot-3{
		0% {
			transform: translate(0px, 0px);
		}
		20% {
			transform: translate(20px, -5px);
		}
		40% {
			transform: translate(40px, 20px);
		}
		60% {
			transform: translate(20px, 40px);
		}
		80% {
			transform: translate(-20px, 30px);
		}
		100% {
			transform: translate(0px, 0px);
		}
	}


	@keyframes moving {
		0% {
			transform: translatey(0px);
		}
		25% {
			transform: translatex(20px);
		}
		50% {
		  transform: translatey(-20px);
		}
		75% {
		  transform: translatex(-20px);
		}
	  
		100% {
			transform: translatey(0px);
		}
	  }

	@keyframes movingleft {
		0% {
			transform: translatey(0px);
		}
		25% {
			transform: translatey(50px);
		}
		50% {
		  transform: translatex(-50px);
		}
		75% {
		  transform: translatey(-20px);
		}
	  
		100% {
			transform: translatex(0px);
		}
	  }

	@keyframes movingright {
		0% {
			transform: translatey(0px);
		}
		25% {
			transform: translatey(30px);
		}
		50% {
		  transform: translatex(-30px);
		}
		75% {
		  transform: translatey(-40px);
		}
	  
		100% {
			transform: translatex(0px);
		}
	  }

	  @keyframes jumpTwo {
		0% {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0)
		}
	
		40% {
			-webkit-transform: translate3d(0, 20px, 0);
			transform: translate3d(0, 20px, 0)
		}
	
		100% {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0)
		}
	}


	@keyframes shine {
		100% {
		 left:125%
		}
	   }

	   @keyframes tptranslateX2{
		0% {
		-webkit-transform: translateX(-30px);
		-moz-transform: translateX(-30px);
		-ms-transform: translateX(-30px);
		-o-transform: translateX(-30px);
		transform: translateX(-30px);
	  }
	  100% {
		-webkit-transform: translatXY(20px);
		-moz-transform: translateX(20px);
		-ms-transform: translateX(20px);
		-o-transform: translateX(20px);
		transform: translateX(20px);
	  }
	}


	@keyframes section-highlight-4 {
		100% {
		width: 100%;
	  }
	}


	@keyframes scale_up_down {
		0% {
		  -webkit-transform: scale(0.9);
		  transform: scale(0.9);
		}
		100% {
		  -webkit-transform: scale(1);
		  transform: scale(1);
		}
	  }

	  @keyframes animationglob {
		0% {
		  -webkit-transform: rotate(0deg);
		  transform: rotate(0deg);
		}
		100% {
		  -webkit-transform: rotate(360deg);
		  transform: rotate(360deg);
		}
	  }


	  // tp up down animtaion
		@keyframes tpleftright{
			0% { 
				transform:translateX(0);
			}
			100% {
				transform:translateX(-20px);
			}
		}


		// tp swing animtaion
		@keyframes tpswing{
			0% { 
				transform:rotate(6deg);
			}
			100% { 
				transform:rotate(-6deg);
			}
		}